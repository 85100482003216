<template>
  <button
    class="card w-100 p-0 alert-info btn-info d-flex align-items-center"
    type="button"
    style="outline: none"
    v-on:click="onClick"
  >
    <div class="card-body">
      <i class="fas fa-plus-circle fa-lg"></i>
      <span class="font-weight-bold ml-3">{{ $t("addDescriptionArea") }}</span>
    </div>
  </button>
</template>
<script>
export default {
  name: "AddButton",
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>