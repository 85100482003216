<template>
  <div class="multiple-description">
    <!-- Button -->
    <AddButton v-if="showCreateButton" v-on:click="createNew" />
    <!-- ./Button -->

    <!-- DescriptionList -->
    <DescriptionList v-bind:name="name" v-model="computedData" />
    <!-- ./DescriptionList -->
  </div>
</template>
<script>
import AddButton from "./components/AddButton/Index.vue";
import DescriptionList from "./components/DescriptionList/Index.vue";
export default {
  name: "WeMultipleDescription",
  components: {
    AddButton,
    DescriptionList,
  },
  props: {
    data: {
      default: () => [],
    },
    name: {
      default: "product",
    },
    quantity: {
      default: 5,
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    createNew() {
      const newData = {
        title: null,
        content: null,
      };

      this.computedData = [...this.computedData, newData];
    },
  },
  computed: {
    computedData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    showCreateButton() {
      return this.computedData.length + 1 <= this.quantity;
    },
  },
};
</script>
<style lang="scss">
.multiple-description {
  margin-bottom: 10px;
}
</style>