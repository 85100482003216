<template>
  <div class="mt-3">
    <ListItem
      v-for="(item, index) in data"
      v-bind:name="name"
      v-bind:key="index"
      v-bind:item-index="index"
      v-bind:data="data"
    />
  </div>
</template>
<script>
const ListItem = () => import("./components/ListItem/Index.vue");
export default {
  name: "DescriptionList",
  components: {
    ListItem,
  },
  props: {
    data: {
      default: () => [],
    },
    name: {
      default: "product",
    },
  },
  model: {
    prop: "data",
  },
  components: {
    ListItem,
  },
};
</script>